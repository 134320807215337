<template>
  <fragment>
    <slot :data="operadoras" :loading="loading"></slot>
  </fragment>
</template>

<script>
import catalogoService from '@/services/catalogoService';

export default {
  name: 'AsyncOperadoras',
  props: {
    uf: {
      type: String,
      required: true,
    },
  },
  watch: {
    uf: {
      immediate: true,
      handler: 'getData',
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      operadoras: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      if (this.uf === '') return;
      try {
        this.loading = true;
        const data = await catalogoService.getOperadorasPorEstado({ estado: this.uf });
        this.$emit('success', data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>

<style>

</style>
