<template>
  <div>
    <the-toolbar title="Consultar Vigência">
    </the-toolbar>
    <v-container class="pa-5 pt-0">
      <div class="text-body- mt-6">Informe uma operadora para consultar as datas de fechamento das vigência.</div>
      <div class="text-caption">
        Esta é apenas uma tela informativa, para saber se a operadora, entidade e o dia da vigência está disponível para comercialização, consulte o material de apoio a venda.
      </div>
      <validation-observer ref="form" tag="form" @submit.prevent="submit" class="mt-4">
        <v-row>
          <v-col cla ss="col-12 col-md-6">
            <async-estados v-slot="{ data, loading}" @success="setEstado">
              <base-autocomplete-field
                :items="data"
                rules="required"
                id="estado"
                name="Estado"
                label="Estado"
                v-model="form.uf"
                :loading="loading"
                outlined
                item-text="nome"
                item-value="id"
                @input="setEstado"
                return-object
              >
              </base-autocomplete-field>
            </async-estados>
          </v-col>
          <v-col class="col-12 col-md-6">
            <asycn-list-operadoras-estado :uf="form.uf ? form.uf.id : ''" v-slot="{ loading}" @success="setOperadoras">
              <base-autocomplete-field
                :disabled="operadoras.length < 1"
                :items="operadoras"
                rules="required"
                id="operadora"
                name="Operadora"
                label="Operadora"
                v-model="form.operadora"
                mode="aggressive"
                :loading="loading"
                outlined
                item-text="nome"
                item-value="nome"
                return-object
              >
              </base-autocomplete-field>
            </asycn-list-operadoras-estado>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            elevation="10"
            color="primary px-9"
            class="secundaryBtn--text mb-6"
            :loading="loading"
            @click="submit">BUSCAR</v-btn>
        </div>
      </validation-observer>
      <v-divider></v-divider>
      <v-row v-if="resultado" justify="center">
        <v-col class="col-12 col-md-8">
          <div class="text-center text-h6 primary--text">Mostrando vigências em {{ form.uf.nome }} na operadora {{ form.operadora.nome }}</div>
          <div class="text-center mt-2" v-if="itens.length === 0">Nenhuma vigência foi encontrada.</div>
          <div v-else>
            <v-simple-table class="mt-5">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      Data Vigência
                    </th>
                    <th class="text-center">
                      Data Fech. Aceitação
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in itens"
                    :key="index"
                  >
                    <td class="text-center">{{ formataData(item.dataVigencia) }}</td>
                    <td class="text-center">{{ formataData2(item.dataFechamentoAceitacao) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TheToolbar from '@/components/TheToolbar.vue';
import AsycnListOperadorasEstado from '@/components/AsyncListOperadorasEstado.vue';
import AsyncEstados from '@/components/AsyncEstados.vue';
import BaseWrapperDialogList from '@/components/BaseWrapperDialogList.vue';
import BaseTextField from '@/components/BaseTextField.vue';
import catalogoService from '@/services/catalogoService';
import vigenciaService from '@/services/vigenciaService';
import BaseAutocompleteField from '@/components/BaseAutocompleteField.vue';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'Vigencia',
  components: {
    TheToolbar, AsycnListOperadorasEstado, AsyncEstados, BaseAutocompleteField,
  },
  data() {
    return {
      table: {
        headers: [
          { text: 'Data Vigência', value: 'dataVigencia' },
          { text: 'Data de fechamento', value: 'dataFechamento' },
        ],
      },
      form: {},
      show: false,
      operadoras: [],
      itens: [],
      resultado: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
  },
  mounted() {
  },
  methods: {
    setEstado(data) {
      let uf;
      if (data.id) {
        this.form.uf = data;
        uf = this.estados.find((estado) => estado.id === data.id);
      } else if (data.length > 0 && !this.estados) {
        this.estados = data;
        uf = data.find((estado) => estado.id === this.user.endereco.estado);
      } else {
        uf = this.estados.find((estado) => estado.id === this.user.endereco.estado);
        this.operadoras = [];
      }
      this.$set(this.form, 'uf', uf);
    },
    setOperadoras(data) {
      this.operadoras = data;
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.loading = true;
      try {
        this.itens = await vigenciaService.consultarVigencia({ uf: this.form.uf.id, operadora: this.form.operadora.nome });
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
      } finally {
        this.loading = false;
        this.resultado = true;
      }
    },
    formataData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formataData2(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss');
    },
  },
};
</script>

<style>

</style>
