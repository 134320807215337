var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("the-toolbar", { attrs: { title: "Consultar Vigência" } }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _c("div", { staticClass: "text-body- mt-6" }, [
            _vm._v(
              "Informe uma operadora para consultar as datas de fechamento das vigência."
            ),
          ]),
          _c("div", { staticClass: "text-caption" }, [
            _vm._v(
              " Esta é apenas uma tela informativa, para saber se a operadora, entidade e o dia da vigência está disponível para comercialização, consulte o material de apoio a venda. "
            ),
          ]),
          _c(
            "validation-observer",
            {
              ref: "form",
              staticClass: "mt-4",
              attrs: { tag: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cla: "", ss: "col-12 col-md-6" } },
                    [
                      _c("async-estados", {
                        on: { success: _vm.setEstado },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ data, loading }) {
                              return [
                                _c("base-autocomplete-field", {
                                  attrs: {
                                    items: data,
                                    rules: "required",
                                    id: "estado",
                                    name: "Estado",
                                    label: "Estado",
                                    loading: loading,
                                    outlined: "",
                                    "item-text": "nome",
                                    "item-value": "id",
                                    "return-object": "",
                                  },
                                  on: { input: _vm.setEstado },
                                  model: {
                                    value: _vm.form.uf,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "uf", $$v)
                                    },
                                    expression: "form.uf",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("asycn-list-operadoras-estado", {
                        attrs: { uf: _vm.form.uf ? _vm.form.uf.id : "" },
                        on: { success: _vm.setOperadoras },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ loading }) {
                              return [
                                _c("base-autocomplete-field", {
                                  attrs: {
                                    disabled: _vm.operadoras.length < 1,
                                    items: _vm.operadoras,
                                    rules: "required",
                                    id: "operadora",
                                    name: "Operadora",
                                    label: "Operadora",
                                    mode: "aggressive",
                                    loading: loading,
                                    outlined: "",
                                    "item-text": "nome",
                                    "item-value": "nome",
                                    "return-object": "",
                                  },
                                  model: {
                                    value: _vm.form.operadora,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "operadora", $$v)
                                    },
                                    expression: "form.operadora",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text mb-6",
                      attrs: {
                        elevation: "10",
                        color: "primary px-9",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("BUSCAR")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _vm.resultado
            ? _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-col", { staticClass: "col-12 col-md-8" }, [
                    _c(
                      "div",
                      { staticClass: "text-center text-h6 primary--text" },
                      [
                        _vm._v(
                          "Mostrando vigências em " +
                            _vm._s(_vm.form.uf.nome) +
                            " na operadora " +
                            _vm._s(_vm.form.operadora.nome)
                        ),
                      ]
                    ),
                    _vm.itens.length === 0
                      ? _c("div", { staticClass: "text-center mt-2" }, [
                          _vm._v("Nenhuma vigência foi encontrada."),
                        ])
                      : _c(
                          "div",
                          [
                            _c("v-simple-table", {
                              staticClass: "mt-5",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [_vm._v(" Data Vigência ")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [_vm._v(" Data Fech. Aceitação ")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.itens,
                                            function (item, index) {
                                              return _c("tr", { key: index }, [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formataData(
                                                          item.dataVigencia
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formataData2(
                                                          item.dataFechamentoAceitacao
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2693876338
                              ),
                            }),
                          ],
                          1
                        ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }